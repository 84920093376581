import { DispatcherWithRegistry } from '../../../../live/src/event-manager/dispatcherWithRegistry';
import {
  ConversationType,
  Message,
} from '../../../../chatbox/livechat-helpers/rfcConversation/Messages';
import { OrchestratorChannel } from '../../orchestratorStore/channel';

export default (
  dispatcher: DispatcherWithRegistry,
  orchestratorChannel: OrchestratorChannel,
) => {
  orchestratorChannel.onAskStartConversation((conversation) => {
    //_______________________________________________________________________
    if (conversation.type === ConversationType.CHAT) {
      //_______________________________________________________________
      const { firstsVisitorMessages: messages, prefetchedMessages } =
        conversation;

      dispatcher.emit('conversation.chat.sendMessage', {
        messages,
        prefetchedMessages: prefetchedMessages as Message[],
      });
    }
  });

  orchestratorChannel.onEscalationAccepted((payload) => {
    dispatcher.emit('conversation.escalation.accepted', payload);
  });

  orchestratorChannel.onEscalationRefused((payload) => {
    dispatcher.emit('conversation.escalation.refused', payload);
  });

  orchestratorChannel.onFeedbackAnswered((payload) => {
    dispatcher.emit('conversation.feedback.sent', payload);
  });
};
