import {
  Message,
  AuthorType,
} from '../../../chatbox/livechat-helpers/rfcConversation/Messages';
import { GlobalIdz, LegacyOperator } from '../../../shared/types/globalTypes';
import * as CHAT_STATUS from '../../../shared/types/chatStatus';

const CREDIT_CARD_PATTERN =
  /\b(?:3[47]\d{2}([ -]?)\d{6}\1\d|(?:(?:4\d|5[1-5]|65)\d{2}|6011)([ -]?)\d{4}\2\d{4}\2)\d{4}\b/g;

const IBAN_PATTERN =
  /(?:((?:IT|SM)\d{2}\s?[A-Z]{1}\d{3}\s?(\d{4}\s?){4}\d{3})|(NL\d{2}\s?[A-Z]{4}\s?(\d{4}\s?){2}\d{2})|(LV\d{2}\s?[A-Z]{4}\s?(\d{4}\s?){3}\d{1})|((?:BG|GB|IE)\d{2}\s?[A-Z]{4}\s?(\d{4}\s?){3}\d{2})|(GI\d{2}\s?[A-Z]{4}\s?(\d{4}\s?){3}\d{3})|(RO\d{2}\s?[A-Z]{4}\s?(\d{4}\s?){4})|(MT\d{2}\s?[A-Z]{4}\s?(\d{4}\s?){5}\d{3})|(NO\d{2}\s?(\d{4}\s?){2}\d{3})|((?:DK|FI|FO)\d{2}\s?(\d{4}\s?){3}\d{2})|((?:SI)\d{2}\s?(\d{4}\s?){3}\d{3})|((?:AT|EE|LU|LT)\d{2}\s?(\d{4}\s?){4})|((?:HR|LI|CH)\d{2}\s?(\d{4}\s?){4}\d)|((?:DE)\d{2}\s?(\d{4}\s?){4}\d{2})|((?:CZ|ES|SK|SE)\d{2}\s?(\d{4}\s?){5})|(PT\d{2}\s?(\d{4}\s?){5}\d)|((?:IS)\d{24})|((?:BE)\d{2}\s?(\d{4}\s?){3})|((?:FR|MC|GR)\d{2}\s?([0-9A-Z]{4}\s?){5}\d{3})|((?:PL|HU|CY)\d{2}\s?(\d{4}\s?){6}))/g;

export function extractOperatorIdFromMessages(rfcMessages?: Message[]) {
  return rfcMessages &&
    rfcMessages[0] &&
    rfcMessages[0].author.type !== AuthorType.UNKNOWN
    ? rfcMessages[0].author.id
    : '';
}

export function isCallConversation(idz: GlobalIdz) {
  return idz.on_call === 1;
}

export const isLegacyOperatorDefined = (
  //________________________________________________________________
  legacyOperator: any,
): legacyOperator is LegacyOperator =>
  legacyOperator && legacyOperator.id !== undefined;

export function setBareJidIfEmpty(idz: GlobalIdz) {
  if (isLegacyOperatorDefined(idz.operator) && !idz.operator.barejid) {
    //_________________________________________
    //_______________________________________________
    idz.operator.barejid = 'i_will_be_back';
  }
}

export function getCurrentTimeString() {
  const currentTime = new Date();
  const hours = currentTime.getHours();
  const minutes = currentTime.getMinutes();

  return `${hours}:${minutes < 10 ? `0${minutes}` : minutes}`;
}

export function isSatisfactionEnabled(idz: GlobalIdz) {
  return idz.preferences.param_satisfaction === '1';
}

export function replaceBankInformation(message: string): string {
  const removedBankCard = message.replace(
    CREDIT_CARD_PATTERN,
    'xxxx-xxxx-xxxx-xxxx',
  );
  const removedIBAN = removedBankCard.replace(
    IBAN_PATTERN,
    'xxxx-xxxx-xxxx-xxxx-xxxx-xxxx-xxx',
  );
  return removedIBAN;
}

export function callOnMessageReceivedCallback(idz: GlobalIdz, msg: string) {
  const now = new Date();
  const minutes = now.getMinutes();

  idz.callbacks.onMessageReceived({
    msg,
    time: `${now.getHours()}:${minutes < 10 ? `0${minutes}` : minutes}`,
    date: now.toISOString(),
    operator: isLegacyOperatorDefined(idz.operator)
      ? {
          externalId: idz.operator.externalId || '',
          id: idz.operator.id,
        }
      : null,
  });
}

export function showUnavailableMessage(idz: GlobalIdz) {
  idz.orchestrator.notifyWaitingIsListFull();

  callOnMessageReceivedCallback(
    idz,
    idz.T('livechat.chatbox.conversation.unavailabilityMessage'),
  );

  //_______________________________________________
  idz.chat.status = CHAT_STATUS.NOT_CHATTING;

  //_______________________________________________
  idz.operator = {};
}
