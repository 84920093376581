export const defaultNotificationPosition = 'unknown';

const positionMap = {
  CORNER_BOTTOM_LEFT: 'left',
  CORNER_BOTTOM_RIGHT: 'right',
} as const;

export const convertNotificationPosition = (
  position?: 'CORNER_BOTTOM_LEFT' | 'CORNER_BOTTOM_RIGHT',
): 'left' | 'right' | 'unknown' =>
  (position && positionMap[position]) || defaultNotificationPosition;
