import { PrefetchedMessage } from '../../../chatbox/livechat-helpers/rfcConversation/Messages';
import { DispatcherWithRegistry } from '../../../live/src/event-manager/dispatcherWithRegistry';
import { GlobalIdz } from '../../../shared/types/globalTypes';
import CustomEventEmitter from '../../../shared/utils/CustomEventEmitter';

export const initializePrefetchScenarioGetter = (
  idz: GlobalIdz,
  dispatcher: DispatcherWithRegistry,
) => {
  const internalDispatcher = CustomEventEmitter('prefetchScenarioGetter');
  const prefetchScenarios: Record<
    number,
    { hasError: boolean; messages?: { data: PrefetchedMessage }[] }
  > = {};

  dispatcher.on(
    'notification.showChatboxWithPrefetchedScenario',
    ({ ruleId, messages }) => {
      prefetchScenarios[ruleId] = {
        hasError: false,
        messages,
      };

      internalDispatcher.emit(`prefetched.${ruleId}`, messages);
    },
  );

  dispatcher.on(
    'notification.showChatboxWithPrefetchedScenarioFailed',
    ({ ruleId }) => {
      prefetchScenarios[ruleId] = {
        hasError: true,
      };

      internalDispatcher.emit(`errored.${ruleId}`);
    },
  );

  const prefetchScenarioGetter = (ruleId: number) =>
    new Promise<{ data: PrefetchedMessage }[]>((resolve, reject) => {
      const currentScenario = prefetchScenarios[ruleId];
      if (currentScenario) {
        if (currentScenario.hasError || !currentScenario.messages) {
          reject();
        } else {
          resolve(currentScenario.messages);
        }
      } else {
        internalDispatcher.once(`prefetched.${ruleId}`, resolve);
        internalDispatcher.once(`errored.${ruleId}`, reject);
      }
    });

  return prefetchScenarioGetter;
};
