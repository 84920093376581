import { v4 as uuid } from 'uuid';
import { EmbeddedConversationStarterTemplate } from '@iadvize/notifications-library/esm/entities/notification';
import {
  addOrReplaceHiddenIframe,
  destroyIframeByID,
} from '../../../../lib/IframeManager';
import { initializePrefetchScenarioGetter } from './prefetchScenarioGetter';
import {
  getAuthorFromLegacyOperator,
  engagementMessageToRfcMessage,
} from '../../../livechat/src/rfcAdapters/rfcAdapter';

import {
  convertNotificationPosition,
  defaultNotificationPosition,
} from './orchestrator/helpers/notification';
import {
  getNotificationPosition,
  saveNotificationPosition,
} from './orchestrator/helpers/storage';

import registerChatBehaviour from './orchestrator/chat';
import registerVideoBehaviour from './orchestrator/video';

import {
  ConversationType,
  Message,
  PrefetchedMessage,
} from '../../../chatbox/livechat-helpers/rfcConversation/Messages';
import getDeviceInformation from '../../../shared/DeviceInformationExtractor';
import noop from '../../../shared/utils/noop';
import { getStaticUrl } from '../../../shared/staticUrl';
import { fixedButtonStore } from '../../../targeting/src/model/rule/action/actions/FixedButtonAction/fixedButtonStore';
import { DispatcherWithRegistry } from '../../../live/src/event-manager/dispatcherWithRegistry';
import { CallOperator, GlobalIdz } from '../../../shared/types/globalTypes';
import { OrchestratorChannel } from '../orchestratorStore/channel';
import { isVisitorIdentityServiceDefined } from '../../../live/src/amd/requireGuards';
import { VisitorIdentityService } from '../../../live/src/visitor-identity/VisitorIdentityService';
import { isLegacyOperatorDefined } from '../../../livechat/src/conversationFlow/helpers';
import { getHandleClickedUrlModule } from '../../../entry/publicMethods/labs/labs';
import sentryLauncher from '../../../shared/utils/sentry/sentryLauncher';
import { GlobalParameters } from '../globalParametersService';
import { getTagConfig } from '../../../entry/tagConfig';
import { VisitorService } from '../../../visitorService/types';
import {
  didVisitorMissConversationClosure as getIsVisitorMissConversationClosure,
  getIdz,
  isChatting,
  getIsFromMail,
  shouldReengage,
} from '../../../shared/globals';
import { getPublicPropertiesStore } from '../../../entry/publicMethods/publicProperties/registerPublicProperties';
import { crossEventEmitter } from '../../../entry/crossEventEmitter';
import { OrchestratorStore } from '../orchestratorStore/store';
import { getDraggableInstance } from '../../../shared/draggable/getDraggableInstance';
import { getClientId } from '../../../entry/visitorContextConfig/websiteConfiguration';
import { setVisitorState } from '../../../visitorService/visitorApiClient';
import { getAddToCartEventEmitter } from '../../../entry/publicMethods/handleAddToCart/handleAddToCart';
import get from '../../../entry/publicMethods/get/get';
import { InputDeviceTypes } from '../../../shared/InputDeviceTypes';

const IFRAME_ID = 'iadvize-orchestrator';

const getConversationType = (idz: GlobalIdz) => {
  if (idz.flags.chat_status !== '2') {
    return null;
  }
  if (idz.flags.visio_status === '1') {
    return ConversationType.VIDEO;
  }
  if (idz.flags.on_call === '1') {
    return ConversationType.CALL;
  }
  return ConversationType.CHAT;
};

const IADVIZE_CONTAINER_ID = 'iadvize-container';

let isOrchestratorLoaded = false;

export const orchestratorUnloader = (dispatcher: DispatcherWithRegistry) => {
  if (!isOrchestratorLoaded) {
    return;
  }

  isOrchestratorLoaded = false;
  dispatcher.clear();

  const container = document.getElementById(IADVIZE_CONTAINER_ID);
  container?.remove();

  destroyIframeByID(IFRAME_ID);
};

export const orchestratorLoader = (
  dispatcher: DispatcherWithRegistry,
  retries: number = 3,
) => {
  //_____________________________________
  if (isOrchestratorLoaded) {
    if (retries > 0) {
      setTimeout(() => orchestratorLoader(dispatcher, retries - 1), 200);
    }
    return;
  }
  isOrchestratorLoaded = true;

  const device = getDeviceInformation();
  const scripts = [`${getStaticUrl()}orchestrator.js`];
  const appContainerId = 'orchestrator';

  const iAdvizeContainer = document.createElement('div');
  iAdvizeContainer.id = IADVIZE_CONTAINER_ID;
  document.body.appendChild(iAdvizeContainer);

  const formatPrefetchedMessages = (
    messages: { data: PrefetchedMessage }[],
  ): Message[] =>
    messages.map((message) => ({
      ...message.data,
      text: message.data.text ? message.data.text : '',
      attachments: message.data.attachments ? [message.data.attachments] : [],
      sentAs: message.data.author,
      createdAt: new Date().toISOString(),
      id: uuid(),
    }));

  //____________________________________________
  const prefetchScenarioGetter = initializePrefetchScenarioGetter(
    window.iAdvize,
    dispatcher,
  );

  const onOrchestratorLoaded = (
    orchestratorChannel: OrchestratorChannel,
    orchestratorStore: OrchestratorStore,
  ) => {
    const { flags, operator = {}, JSONStore } = window.iAdvize;

    const publicPropertiesStore = getPublicPropertiesStore();
    dispatcher.once('reloadTag', () => {
      (
        getIdz().require<VisitorService>('visitorService') as VisitorService
      ).maintainVisitorState();
      dispatcher.emit('tag.livechat.stop');
      dispatcher.emit('tag.orchestrator.stop');
      dispatcher.emit('tag.orchestrator.start');
    });

    dispatcher.on('auth.authenticatedSessionStarted', () => {
      orchestratorChannel.setAuthenticationState('AUTHENTICATED');
    });

    dispatcher.on('auth.authenticatedSessionFinished', (data) => {
      const state = data?.cause === 'error' ? 'EXPIRED' : 'ANONYMOUS';
      orchestratorChannel.setAuthenticationState(state);
    });

    dispatcher.on('notification.hover', (data) => {
      orchestratorChannel.hoverNotification(data.isHover);
    });

    let isAlreadyOpen = false;

    registerChatBehaviour(dispatcher, orchestratorChannel);
    registerVideoBehaviour(dispatcher, orchestratorChannel, orchestratorStore);
    window.iAdvize.orchestrator = orchestratorChannel;

    if (isLegacyOperatorDefined(operator)) {
      orchestratorChannel.setCurrentOperator(
        getAuthorFromLegacyOperator(operator),
      );
    }

    //____________________________________________________
    dispatcher.on(
      'engagement.showChatboxButtonClickedBeforeBundle',
      (params) => {
        if (params.position) {
          const position = convertNotificationPosition(params.position);
          saveNotificationPosition(position);
          orchestratorChannel.setNotificationPosition(position);
        }

        //_____________________________
        dispatcher.once('engagement.showChatbox', ({ actionId }) => {
          if (params.actionId !== actionId) {
            return;
          }
          //__________________________________________________________________________________________________________________
          if (isAlreadyOpen && window.iAdvize.chat.status !== undefined) {
            orchestratorChannel.shouldAnimate(true);
          }
          isAlreadyOpen = true;
          const isDesktop = device.deviceType === 'desktop';
          orchestratorChannel.openChatbox({
            shouldFocusComposeZone: isDesktop,
          });
        });

        const hasPrefetchedScenario = params.isPrefetchCase;

        //__________________________________________
        if (hasPrefetchedScenario) {
          const prefetchScenario = prefetchScenarioGetter(params.ruleId);
          prefetchScenario
            .then((botMessages) => {
              const formattedBotMessages =
                formatPrefetchedMessages(botMessages);
              orchestratorChannel.proactiveMessagesFetched(
                formattedBotMessages,
              );
            })
            .catch(() => {
              orchestratorChannel.proactiveMessagesFetched([]);
            });
        }
        orchestratorChannel.initializeChatbox({
          shouldDisplayWelcomeMessage: !hasPrefetchedScenario,
          conversation:
            orchestratorChannel.initializeChatboxParametersHelper.createNotStartedChatConversation(
              hasPrefetchedScenario,
            ),
        });
      },
    );

    //_______________________________________________________________
    dispatcher.on(
      'engagement.showChatboxNotificationBeforeBundle',
      ({ actionId, prefetchedMessages }) => {
        saveNotificationPosition(defaultNotificationPosition);
        orchestratorChannel.setNotificationPosition(
          defaultNotificationPosition,
        );

        dispatcher.once('engagement.showChatbox', (params) => {
          if (params.actionId !== actionId) {
            return;
          }
          orchestratorChannel.openChatbox({
            shouldFocusComposeZone: false,
            shouldAnimateChatbox: false,
          });
          isAlreadyOpen = true;
        });
        const hasPrefetchedScenario = prefetchedMessages.length > 0;
        orchestratorChannel.initializeChatbox({
          shouldDisplayWelcomeMessage: !hasPrefetchedScenario,
          conversation:
            orchestratorChannel.initializeChatboxParametersHelper.createNotStartedChatConversation(
              hasPrefetchedScenario,
            ),
        });
        //___________________________________________
        if (hasPrefetchedScenario) {
          const formattedBotMessages =
            formatPrefetchedMessages(prefetchedMessages);
          orchestratorChannel.proactiveMessagesFetched(formattedBotMessages);
        }
        const cancelChatboxHandler = (params: { actionId: string }) => {
          orchestratorChannel.onGDPRWorkflowDone(noop);

          if (actionId === params.actionId) {
            dispatcher.off('engagement.cancelChatbox', cancelChatboxHandler);
            orchestratorChannel.resetChatbox({ isUserIntended: false });
          }
        };
        dispatcher.on('engagement.cancelChatbox', cancelChatboxHandler);
      },
    );

    //_______________________________________________________________
    dispatcher.on(
      'engagement.showMessageNotificationBeforeBundle',
      ({ actionId, message, prefetchedMessages }) => {
        const hasPrefetchedScenario = prefetchedMessages.length > 0;
        dispatcher.once('engagement.showMessage', (params) => {
          if (actionId !== params.actionId) {
            return;
          }
          //________________________________________________________________________________
          //________________________________________________________
          orchestratorChannel.receiveMessage(
            engagementMessageToRfcMessage(message, actionId),
          );
          //___________________________________________
          if (hasPrefetchedScenario) {
            const formattedBotMessages =
              formatPrefetchedMessages(prefetchedMessages);
            orchestratorChannel.proactiveMessagesFetched(formattedBotMessages);
          }
          orchestratorChannel.openChatbox({
            shouldFocusComposeZone: false,
            shouldAnimateChatbox: false,
          });
          isAlreadyOpen = true;
        });

        saveNotificationPosition(defaultNotificationPosition);
        orchestratorChannel.setNotificationPosition(
          defaultNotificationPosition,
        );

        setTimeout(
          () =>
            //_____________________________________________________________________________
            orchestratorChannel.initializeChatbox({
              shouldDisplayWelcomeMessage: false,
              conversation:
                orchestratorChannel.initializeChatboxParametersHelper.createNotStartedChatConversation(
                  hasPrefetchedScenario,
                ),
            }),
          0,
        );

        const cancelMessageHandler = (params: { actionId: string }) => {
          if (actionId === params.actionId) {
            dispatcher.off('engagement.cancelMessage', cancelMessageHandler);
            orchestratorChannel.resetChatbox({ isUserIntended: false });
          }
        };
        dispatcher.on('engagement.cancelMessage', cancelMessageHandler);
      },
    );

    getAddToCartEventEmitter().on(
      'displayAddToCartMessage',
      (productName, success) => {
        orchestratorChannel.addToCartMessage({ productName, success });
      },
    );

    dispatcher.on('conversation.chat.started', ({ chatId, conversationId }) => {
      /*_______________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________*/
      setTimeout(() => {
        if (chatId) {
          orchestratorChannel.startConversation(
            orchestratorChannel.initializeChatboxParametersHelper.createOngoingChatConversation(
              chatId,
              conversationId,
            ),
          );
        }
      }, 0);
    });

    dispatcher.on('xmpp.connection.reconnected', () => {
      orchestratorChannel.xmppReconnected();
    });

    dispatcher.on('xmpp.connection.closed-while-away', async () => {
      const chatId = getIdz().chat.history_id;
      await (
        getIdz().require<VisitorService>('visitorService') as VisitorService
      ).maintainVisitorState();

      //_____________________________________________________________________________________________________
      const didMissConversationClosure = getIsVisitorMissConversationClosure();
      const isBotConversation =
        orchestratorChannel.getOrchestratorState().isProactiveConversation;
      if (didMissConversationClosure && !isBotConversation) {
        dispatcher.emit('conversation.closedByAgent', {
          payload: { isSurveyDisabled: false, forceSurveyDisplay: true },
        });
      }

      if (chatId) {
        orchestratorChannel.xmppClosedWhileAway({
          chatId,
          didMissConversationClosure,
        });
      }
    });

    let hasAlreadyInitialized = false;

    orchestratorChannel.onInitializeChatbox(() => {
      if (!hasAlreadyInitialized) {
        window.iAdvize.callbacks.onChatDisplayed();
        hasAlreadyInitialized = true;
      }
    });

    orchestratorChannel.onResetChatbox(({ isUserIntended }) => {
      dispatcher.emit('targeting.fixedButton.rules.run');
      if (isUserIntended) {
        window.iAdvize.callbacks.onChatHidden();
      }
    });

    orchestratorChannel.onVisitorStartedTyping(() =>
      dispatcher.emit('conversation.chat.onVisitorStartedTyping'),
    );
    orchestratorChannel.onMessageComposed((message) =>
      dispatcher.emit('conversation.chat.onMessageComposed', {
        payload: message,
      }),
    );
    orchestratorChannel.onVisitorStoppedTyping(() =>
      dispatcher.emit('conversation.chat.onVisitorStoppedTyping'),
    );
    orchestratorChannel.onMessageSubmitted(() =>
      dispatcher.emit('conversation.chat.onMessageSubmitted'),
    );
    orchestratorChannel.onUpdateNotificationSettingsSuccess(
      ({ correlationId }) => {
        dispatcher.emit('conversation.notificationSettings.updated', {
          payload: { correlationId: correlationId! },
        });
      },
    );

    let isDataPipelineLoaded = get('visitor:cookiesConsent');

    dispatcher.on('dataPipeline.started', () => {
      isDataPipelineLoaded = true;
    });

    orchestratorChannel.onGdprRefused(({ consentType }) => {
      //___________________________________________________________________________
      dispatcher.emit('notification.clearStarterId');

      if (isDataPipelineLoaded) {
        dispatcher.emit(
          'EngagementVisitorGDPRConsentRefused',
          'chatbox',
          consentType,
        );
      } else {
        dispatcher.on('dataPipeline.started', () => {
          dispatcher.emit(
            'EngagementVisitorGDPRConsentRefused',
            'chatbox',
            consentType,
          );
        });
      }
    });

    orchestratorChannel.onGdprAccepted(({ consentType }) => {
      //___________________________________________________________________________
      if (isDataPipelineLoaded) {
        dispatcher.emit(
          'EngagementVisitorGDPRConsentAccepted',
          'chatbox',
          consentType,
        );
      } else {
        dispatcher.on('dataPipeline.started', () => {
          dispatcher.emit(
            'EngagementVisitorGDPRConsentAccepted',
            'chatbox',
            consentType,
          );
        });
      }
    });

    orchestratorChannel.onGdprDisplayed(({ consentType }) => {
      dispatcher.emit(
        'EngagementVisitorGDPRConsentDisplayed',
        'chatbox',
        consentType,
      );
    });

    orchestratorChannel.onCobrowsingAccepted(() => {
      dispatcher.emit('cobrowsing.accepted');
    });

    orchestratorChannel.onCobrowsingRefused(() => {
      dispatcher.emit('cobrowsing.refused');
    });

    orchestratorChannel.onSurveyClosed(() => {
      dispatcher.emit('targeting.fixedButton.rules.run');
      window.iAdvize.callbacks.onSatisfactionAnswered();
    });

    orchestratorChannel.onMessageSubmitted((payload) => {
      if (payload.message !== '') {
        dispatcher.emit('conversation.chat.sendMessage', {
          messages: [payload],
          prefetchedMessages: [],
        });
      }
    });

    orchestratorChannel.onTrackingEventEmitted((payload) =>
      dispatcher.emit('dataPipeline.chatboxTrackingEvent', payload),
    );
    orchestratorChannel.onSurveyEventEmitted(({ name, properties }) =>
      dispatcher.emit(name, properties),
    );

    orchestratorChannel.onPickedUp((payload) => {
      dispatcher.emit('conversation.video.pickedUp', payload);
    });

    orchestratorChannel.onHungUp((payload) => {
      dispatcher.emit('conversation.video.hungUp', payload);
    });

    orchestratorChannel.onChatboxClosed(() => {
      publicPropertiesStore.dispatch('chatbox:status', 'CLOSED');
      dispatcher.emit('engagement.chatboxClosed');
      dispatcher.emit('dataPipeline.chatboxTrackingEvent', {
        event: 'conversations.chatbox.chatboxClosed',
        properties: {},
      });
      //_____________________________
      getPublicPropertiesStore().dispatch('app:boundaries', {
        width: 0,
        height: 0,
        left: 0,
        bottom: 0,
      });
    });

    orchestratorChannel.onHistoryFetched(() => {
      if (isChatting()) {
        dispatcher.emit('tag.livechat.start');
      }
    });

    //______________________
    orchestratorChannel.onCrossedTabConversationStarted((isLocalEmitter) => {
      if (!isLocalEmitter) {
        dispatcher.emit('targeting.pauseAndHide');
        setTimeout(() => {
          dispatcher.emit('reloadTag');
          //___________________________________________________________
        }, 2000);
      }
    });

    orchestratorChannel.onCrossedTabLastSeenMessageDateUpdated(
      (isLocalEmitter) => {
        if (!isLocalEmitter) {
          dispatcher.emit('conversation.lastSeenMessageDate.updated');
          orchestratorChannel.updateLastSeenMessageDate();
        }
      },
    );

    orchestratorChannel.onCrossedTabSetCurrentOperator((isLocalEmitter) => {
      if (
        !isLocalEmitter &&
        (!window.iAdvize.operator ||
          Object.keys(window.iAdvize.operator).length === 0)
      ) {
        setVisitorState().then((state) => {
          if (state.operator) {
            window.iAdvize.operator = state.operator;
          }
        });
      }
    });

    //_________________________
    orchestratorChannel.onChatboxOpened(() => {
      publicPropertiesStore.dispatch('chatbox:status', 'OPENED');
      const state = orchestratorChannel.getOrchestratorState();

      if (state.chatboxState !== 'OPEN') {
        orchestratorChannel.openChatbox({
          shouldFocusComposeZone: true,
          shouldAnimateChatbox: false,
        });
      }
    });
    orchestratorChannel.onChatboxReduced(() => {
      publicPropertiesStore.dispatch('chatbox:status', 'REDUCED');
    });
    orchestratorChannel.onSetCurrentOperator((currentOperator) => {
      fixedButtonStore.dispatch('refreshDisplayedAvatars', {
        isOngoingConversation: currentOperator.type !== 'UNKNOWN',
      });
    });
    //_________________________________
    (['conversation.closedByAgent'] as const).forEach(
      (conversationStatusChangedEvent) => {
        dispatcher.on(conversationStatusChangedEvent, () => {
          fixedButtonStore.dispatch('conversationStatus', 'NOT_ONGOING');
          publicPropertiesStore.dispatch('conversation:id', null);
        });
      },
    );
    (['call.failed', 'call.stopped'] as const).forEach(
      (conversationStatusChangedEvent) => {
        dispatcher.on(conversationStatusChangedEvent, () => {
          if (window.iAdvize.callOffline?.enabled) {
            fixedButtonStore.dispatch('conversationStatus', 'NOT_ONGOING');
            publicPropertiesStore.dispatch('conversation:id', null);
          }
        });
      },
    );
    //__________________________________
    (
      [
        'call.operatorAnswered',
        'call.started',
        'conversation.chat.started',
        'conversation.video.routing.completed',
      ] as const
    ).forEach((conversationStatusChangedEvent) => {
      dispatcher.on(conversationStatusChangedEvent, () => {
        fixedButtonStore.dispatch('conversationStatus', 'ONGOING');
      });
    });

    orchestratorChannel.onSetHeaderAgent(({ agent }) => {
      fixedButtonStore.dispatch('refreshDisplayedAvatars', {
        isOngoingConversation: true,
        agent,
      });
    });

    orchestratorChannel.onSetUnreadMessageCount((unreadMessageCount) => {
      fixedButtonStore.dispatch('unreadMessageCount', unreadMessageCount);
    });

    dispatcher.once('livechatLoaded', () => {
      if (shouldReengage()) {
        //__________________________________________________________________________________
        //___________________________________________________
        orchestratorChannel.initializeChatbox({
          shouldDisplayWelcomeMessage: getIsFromMail(),
          conversation:
            orchestratorChannel.initializeChatboxParametersHelper.createNotStartedChatConversation(),
        });
        orchestratorChannel.openChatbox({
          shouldFocusComposeZone: true,
          shouldAnimateChatbox: false,
        });
        isAlreadyOpen = true;
        JSONStore.save({
          //_______________________________________
          chat_status_witnessed: flags.chat_status,
          //_______________________________________
          previous_conversation_id: flags.previous_conversation_id,
        });
      }
    });
    dispatcher.emit('orchestrator.loaded');

    if ('is_online' in operator) {
      window.iAdvize.orchestrator?.setOperatorPresence(
        Number((operator as CallOperator).is_online) === 1,
      );
    }

    //________________________________________________
    if (
      flags.chat_status === '2' &&
      flags.visio_status !== '1' &&
      flags.history_id &&
      flags.conversationId &&
      flags.c2coperator !== '-2' //__________________________________________________
    ) {
      //_______________________________________________________________________________
      orchestratorChannel.initializeChatbox({
        shouldDisplayWelcomeMessage: true,
        conversation:
          orchestratorChannel.initializeChatboxParametersHelper.createOngoingChatConversation(
            flags.history_id,
            flags.conversationId,
          ),
      });
    }

    if (window.location.search.indexOf('idzDisableNotifications') !== -1) {
      orchestratorChannel.initializeChatbox({
        shouldDisplayWelcomeMessage: false,
        conversation:
          orchestratorChannel.initializeChatboxParametersHelper.createNotStartedChatConversation(),
      });

      orchestratorChannel.openChatbox({
        shouldFocusComposeZone: false,
      });
      isAlreadyOpen = true;
      orchestratorChannel.openSettingsPanel();
      orchestratorChannel.setNotificationEnabled(false);
    }

    //_________________________________________________________________
    fixedButtonStore.on('firstVisitorMessage', (firstVisitorMessage) => {
      if (!firstVisitorMessage) {
        return;
      }
      orchestratorStore.dispatch('firstVisitorMessage', firstVisitorMessage);
      //____________________________________________________________________
      const { chatboxInitialized } = orchestratorStore.getState();
      if (chatboxInitialized) {
        orchestratorChannel.sendFirstVisitorMessage();
      }
    });
    dispatcher.on(
      'notification.clicked',
      ({ action, engagementRuleId, channel, optionalActionParameters }) => {
        /*______________________________________________________________________________________________________________________________________________________________*/
        orchestratorChannel.setNotificationTemplate(action.notification);
        let notificationText: string | undefined;

        //_________________________________________________________________________________
        if (
          action.notification.templateType ===
            'EMBEDDED_CONVERSATION_STARTER' &&
          optionalActionParameters?.starterId
        ) {
          const firstMessage = (
            action.notification as EmbeddedConversationStarterTemplate
          ).templateAttributes.starters.find(
            (starter) => starter.id === optionalActionParameters.starterId,
          )?.staticText?.[getIdz().curlang.toUpperCase()];

          if (firstMessage) {
            notificationText = firstMessage;
            orchestratorStore.dispatch('firstVisitorMessage', firstMessage);
            //____________________________________________________________________
            const { chatboxInitialized } = orchestratorStore.getState();
            if (chatboxInitialized) {
              orchestratorChannel.sendFirstVisitorMessage();
            }
          }
        }

        getPublicPropertiesStore().dispatch('engagementNotification:clicked', {
          text: notificationText,
          channel,
          type:
            action.notification.templateType === 'FIXED'
              ? 'CUSTOM_BUTTON'
              : action.notification.templateType,
          id: action.notification.id,
          ruleId: engagementRuleId,
        });

        //_____________________________
        getPublicPropertiesStore().dispatch('app:boundaries', {
          width: 0,
          height: 0,
          left: 0,
          bottom: 0,
        });
      },
    );

    dispatcher.on(
      'notification.currentInputModeChange',
      (currentInputMode: InputDeviceTypes) => {
        orchestratorStore.dispatch('currentInputMode', currentInputMode);
      },
    );
  };

  const visitorIdentityService = window.iAdvize.require<VisitorIdentityService>(
    'visitorIdentityService',
  );

  if (!isVisitorIdentityServiceDefined(visitorIdentityService)) {
    return;
  }

  const notificationPosition = getNotificationPosition();

  const staticUrl = getStaticUrl();
  Promise.all([
    getHandleClickedUrlModule(),
    visitorIdentityService.isAuthenticated(),
  ]).then(([{ getIsClickedUrlHandled }, isAuthenticated]) => {
    const {
      flags,
      featureFlags,
      chatboxTemplate,
      curlang,
      customizeTranslate,
      engagement: {
        url: engagementUrl,
        gdprConsent: { enabled: gdprConsentEnabled },
      },
      implicitGdprConsentEnabled,
    } = window.iAdvize;

    const customTranslations = Object.entries(customizeTranslate).reduce<{
      [key: string]: string;
    }>((acc, [key, value]) => {
      const translationKey = `custom.${key}`;
      acc[translationKey] = value[curlang] || value.en || '';
      return acc;
    }, {});

    addOrReplaceHiddenIframe(
      document.body,
      {
        staticUrl,
        notificationPosition,
        iAdvizeContainer,
        flags,
        featureFlags,
        chatboxTemplate,
        satisfactionSurveyEnabled: Boolean(
          +window.iAdvize.preferences.param_satisfaction,
        ),
        styles: {
          sandboxStyle: `${staticUrl}sandboxStyle.css`,
          chatboxV2: `${staticUrl}ChatboxApp.chunk.css`,
          fontIcons: `${staticUrl}fontIcons.css`,
        },
        onOrchestratorLoaded,
        chatUrl: window.iAdvize.chaturl,
        conversationApiUrl:
          window.iAdvize.services['conversations-api-service'],
        graphqlUrl: window.iAdvize.graphql.url,
        platform: window.iAdvize.platform,
        vuid: window.iAdvize.vuid,
        websiteId: window.iAdvize.website_id,
        clientId: getClientId(),
        vProf: window.iAdvize.vProf,
        version: process.env.BUILD_IDENTIFIER!,
        getTranslations: () => ({
          ...window.iAdvize.trad,
          ...customTranslations,
        }),
        locale: curlang,
        anonymizationDelay: window.iAdvize.preferences.anonymization_delay,
        visitor: {
          device,
          notificationEmail: window.iAdvize.vProf.notificationEmail || '',
          notificationEnabled:
            window.iAdvize.vProf.notificationEnabled || false,
        },
        apiUrls: {
          engagementGDPRConsent: engagementUrl,
        },
        isFromMail: getIsFromMail(),
        gdprConsentEnabled,
        implicitGdprConsentEnabled,
        gdprConsent: false,
        isRestoredConversation: Boolean(+window.iAdvize.restored_conversation),
        isMirroringEnabled: Boolean(
          +window.iAdvize.preferences.mirroring_enabled,
        ),
        websiteOfficeHours: window.iAdvize.officeHours,
        websiteTimezoneOffset: window.iAdvize.websiteTimezoneOffset,
        conversationType: getConversationType(window.iAdvize),
        isAuthenticated,
        proactifData: window.iAdvize.proactifData,
        getIsClickedUrlHandled,
        sentryLauncher,
        sentryConfig: window.iAdvize.sentry,
        tagConfig: getTagConfig(),
        publicPropertiesStore: getPublicPropertiesStore(),
        addToCartEventEmitter: getAddToCartEventEmitter(),
        getHost: () => window.host,
        crossEventEmitter,
        draggable: getDraggableInstance(),
      } as GlobalParameters,
      IFRAME_ID,
      appContainerId,
      window.iAdvize.curlang,
      scripts,
    );
  });
};
