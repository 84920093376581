import { UUID } from '../../app-helpers/index';
import {
  MessageAuthor,
  MessageConversationType,
  WaitingTimeEstimation,
} from './Messages';

export enum SystemMessageAttachementType {
  COBROWSING_ASKED = 'COBROWSING_ASKED',
  COBROWSING_STOPPED = 'COBROWSING_STOPPED',
  CONVERSATION_CLOSED = 'CONVERSATION_CLOSED',
  PARTICIPANT_LEFT = 'PARTICIPANT_LEFT',
  PARTICIPANT_RETURNED = 'PARTICIPANT_RETURNED',
  VISITOR_BLOCKED = 'VISITOR_BLOCKED',
  ENGAGEMENT_RULE_TRIGGERED = 'ENGAGEMENT_RULE_TRIGGERED',
  NAVIGATION_CHANGED = 'NAVIGATION_CHANGED',
  COBROWSING_REFUSED = 'COBROWSING_REFUSED',
  COBROWSING_ACCEPTED = 'COBROWSING_ACCEPTED',
  VISITOR_CHATBOX_CLOSED = 'VISITOR_CHATBOX_CLOSED',
  VISITOR_CHATBOX_MAXIMIZED = 'VISITOR_CHATBOX_MAXIMIZED',
  VISITOR_CHATBOX_MINIFIED = 'VISITOR_CHATBOX_MINIFIED',
  SUPERVISOR_COMMENT_SENT = 'SUPERVISOR_COMMENT_SENT',
  TRANSFERRED_TO_PARTICIPANT = 'TRANSFERRED_TO_PARTICIPANT',
  TRANSFERRED_TO_ROUTING_RULE = 'TRANSFERRED_TO_ROUTING_RULE',
  AUTOMATIC_MESSAGE_SENT = 'AUTOMATIC_MESSAGE_SENT',
  VISITOR_NOTIFICATION_SETTINGS_REQUESTED = 'VISITOR_NOTIFICATION_SETTINGS_REQUESTED',
  VISITOR_NOTIFICATION_SETTINGS_SET = 'VISITOR_NOTIFICATION_SETTINGS_SET',
  VISITOR_NOTIFICATION_SETTINGS_CONFIRMED = 'VISITOR_NOTIFICATION_SETTINGS_CONFIRMED',
  WAITING_TIME_ESTIMATED = 'WAITING_TIME_ESTIMATED',
  WAITING_TIME_EXCEEDED = 'WAITING_TIME_EXCEEDED',
}

export type SystemConversationEvent = {
  type:
    | SystemMessageAttachementType.COBROWSING_ASKED
    | SystemMessageAttachementType.COBROWSING_STOPPED
    | SystemMessageAttachementType.CONVERSATION_CLOSED
    | SystemMessageAttachementType.PARTICIPANT_LEFT
    | SystemMessageAttachementType.PARTICIPANT_RETURNED
    | SystemMessageAttachementType.VISITOR_BLOCKED;
  data: {
    participant: MessageAuthor;
  };
};

export type SystemEngagementTriggered = {
  type: SystemMessageAttachementType.ENGAGEMENT_RULE_TRIGGERED;
  data: {
    ruleName: string;
  };
};

export type SystemNavigationChanged = {
  type: SystemMessageAttachementType.NAVIGATION_CHANGED;
  data: {
    url: string;
  };
};

export type SystemConversationEventFromVisitor = {
  type:
    | SystemMessageAttachementType.COBROWSING_REFUSED
    | SystemMessageAttachementType.COBROWSING_ACCEPTED
    | SystemMessageAttachementType.VISITOR_CHATBOX_CLOSED
    | SystemMessageAttachementType.VISITOR_CHATBOX_MAXIMIZED
    | SystemMessageAttachementType.VISITOR_CHATBOX_MINIFIED;
  data: {};
};

export type SystemSupervisorCommentSent = {
  type: SystemMessageAttachementType.SUPERVISOR_COMMENT_SENT;
  data: {
    text: string;
  };
};

export type SystemTransferredToParticipant = {
  type: SystemMessageAttachementType.TRANSFERRED_TO_PARTICIPANT;
  data: {
    fromParticipant: MessageAuthor;
    toParticipant: MessageAuthor;
    reason: string;
  };
};

export type SystemTransferredToRoutingRule = {
  type: SystemMessageAttachementType.TRANSFERRED_TO_ROUTING_RULE;
  data: {
    fromParticipant: MessageAuthor;
    toRule: {
      routingRuleId: string;
      routingRuleLabel: string;
    };
    reason: string;
  };
};

export type SystemAutomaticMessageSent = {
  type: SystemMessageAttachementType.AUTOMATIC_MESSAGE_SENT;
  data: {
    legacyMagicId: number;
    participant: MessageAuthor;
    text: string;
  };
};

export type SystemVisitorNotificationSettingsRequested = {
  type: SystemMessageAttachementType.VISITOR_NOTIFICATION_SETTINGS_REQUESTED;
  data: {
    correlationId: UUID;
    text: string;
  };
};

export type SystemVisitorNotificationSettingsSet = {
  type: SystemMessageAttachementType.VISITOR_NOTIFICATION_SETTINGS_SET;
  data: {
    correlationId: UUID;
  };
};

export type SystemVisitorNotificationSettingsConfirmed = {
  type: SystemMessageAttachementType.VISITOR_NOTIFICATION_SETTINGS_CONFIRMED;
  data: {
    correlationId: UUID;
    text: string;
  };
};

export type SystemWaitingTimeEstimated = {
  type: SystemMessageAttachementType.WAITING_TIME_ESTIMATED;
  data: {
    message: string;
    estimation: WaitingTimeEstimation;
  };
};

export type SystemWaitingTimeExceeded = {
  type: SystemMessageAttachementType.WAITING_TIME_EXCEEDED;
  data: {
    message: string;
  };
};

export type SystemWaitingTimeMessage = {
  id: string;
  type: MessageConversationType.SYSTEM_MESSAGE;
  createdAt: string; //__________________
  attachments: SystemWaitingTimeEstimated[] | SystemWaitingTimeExceeded[];
};

export type SystemMessageAttachement =
  | SystemConversationEvent
  | SystemEngagementTriggered
  | SystemNavigationChanged
  | SystemConversationEventFromVisitor
  | SystemSupervisorCommentSent
  | SystemTransferredToParticipant
  | SystemTransferredToRoutingRule
  | SystemAutomaticMessageSent
  | SystemVisitorNotificationSettingsRequested
  | SystemVisitorNotificationSettingsSet
  | SystemVisitorNotificationSettingsConfirmed
  | SystemWaitingTimeEstimated
  | SystemWaitingTimeExceeded;

export type SystemMessage = {
  id: string;
  type: MessageConversationType.SYSTEM_MESSAGE;
  createdAt: string; //__________________
  attachments: SystemMessageAttachement[];
};
