import { convertNotificationPosition } from './helpers/notification';
import { saveNotificationPosition } from './helpers/storage';
import { ConversationType } from '../../../../chatbox/livechat-helpers/rfcConversation/Messages';
import DeviceInformationExtractor from '../../../../shared/DeviceInformationExtractor';
import { DispatcherWithRegistry } from '../../../../live/src/event-manager/dispatcherWithRegistry';
import { OrchestratorChannel } from '../../orchestratorStore/channel';
import { VideoConversationCreationResponse } from '../../../../livechat/src/conversationFlow/types';
import { OrchestratorStore } from '../../orchestratorStore/store';

const registerVideoButtonClicked = (
  dispatcher: DispatcherWithRegistry,
  orchestratorChannel: OrchestratorChannel,
  orchestratorStore: OrchestratorStore,
) => {
  dispatcher.on('conversations.visio.requestedBeforeBundle', (params) => {
    const position = convertNotificationPosition(params.position);

    saveNotificationPosition(position);
    orchestratorChannel.setNotificationPosition(position);

    const openChatboxHandler = (args: { actionId: string }) => {
      if (params.actionId !== args.actionId) {
        return;
      }

      const { isVideoLobbyEnabled } = orchestratorStore.getState();

      setTimeout(() => {
        if (
          DeviceInformationExtractor().deviceType !== 'mobile' &&
          !isVideoLobbyEnabled
        ) {
          orchestratorChannel.openChatbox({
            shouldFocusComposeZone: false,
          });
        }
        orchestratorChannel.openVideobox();
      }, 0);
      dispatcher.off('conversations.visio.requested', openChatboxHandler);
    };
    dispatcher.on('conversations.visio.requested', openChatboxHandler);

    orchestratorChannel.initializeChatbox({
      shouldDisplayWelcomeMessage: false,
      conversation:
        orchestratorChannel.initializeChatboxParametersHelper.createNotStartedVideoConversation(),
    });
  });
};

const startVideoConversationHandler = (
  dispatcher: DispatcherWithRegistry,
  orchestratorChannel: OrchestratorChannel,
) => {
  let videoConversationState: VideoConversationCreationResponse | null = null;

  dispatcher.on('conversation.video.created', (state) => {
    videoConversationState = state;
  });

  const startConversation = (state: VideoConversationCreationResponse) => {
    orchestratorChannel.startConversation(
      orchestratorChannel.initializeChatboxParametersHelper.createOngoingVideoConversation(
        state.historyId,
        state.conversationId,
        state.conversationKey,
      ),
    );
  };

  dispatcher.on('conversation.video.routing.completed', () => {
    if (videoConversationState) {
      startConversation(videoConversationState);
    } else {
      dispatcher.once('conversation.video.created', startConversation);
    }
  });

  dispatcher.emit('conversation.video.create');
};

const restoreConversationIfNecessary = (
  orchestratorChannel: OrchestratorChannel,
) => {
  const { flags } = window.iAdvize;
  if (
    flags.chat_status === '2' &&
    flags.visio_status === '1' &&
    flags.history_id &&
    flags.conversationId &&
    flags.conversationKey
  ) {
    orchestratorChannel.initializeChatbox({
      shouldDisplayWelcomeMessage: false,
      conversation:
        orchestratorChannel.initializeChatboxParametersHelper.createOngoingVideoConversation(
          flags.history_id,
          flags.conversationId,
          flags.conversationKey,
        ),
    });
  }
};

const registerTimeoutEvent = (
  dispatcher: DispatcherWithRegistry,
  orchestratorChannel: OrchestratorChannel,
) => {
  dispatcher.on('conversation.video.timeout', () => {
    orchestratorChannel.operatorMissedVideoConversation();
  });
};

const registerRefusedEvent = (
  dispatcher: DispatcherWithRegistry,
  orchestratorChannel: OrchestratorChannel,
) => {
  dispatcher.on('conversation.video.refused', () => {
    orchestratorChannel.operatorMissedVideoConversation();
  });
};

const registerOperatorPickedUpEvent = (
  dispatcher: DispatcherWithRegistry,
  orchestratorChannel: OrchestratorChannel,
) => {
  dispatcher.on('conversation.video.operator.pickedUp', () => {
    orchestratorChannel.operatorPickedUp();
  });
};

export default (
  dispatcher: DispatcherWithRegistry,
  orchestratorChannel: OrchestratorChannel,
  orchestratorStore: OrchestratorStore,
) => {
  registerTimeoutEvent(dispatcher, orchestratorChannel);
  registerRefusedEvent(dispatcher, orchestratorChannel);
  registerVideoButtonClicked(
    dispatcher,
    orchestratorChannel,
    orchestratorStore,
  );
  restoreConversationIfNecessary(orchestratorChannel);
  orchestratorChannel.onAskStartConversation((conversation) => {
    if (conversation.type === ConversationType.VIDEO) {
      startVideoConversationHandler(dispatcher, orchestratorChannel);
    }
  });
  registerOperatorPickedUpEvent(dispatcher, orchestratorChannel);
};
