import { VisitorIdentityService } from '../visitor-identity/VisitorIdentityService';
import { MirroringService } from '../../../livechat/src/mirroring/types';
import { VisitorService } from '../../../visitorService/types';

const isModuleDefined =
  <T>(testMethod: keyof T) =>
  (module: T | {}): module is T =>
    !!(module as T)[testMethod];

export const isVisitorIdentityServiceDefined =
  isModuleDefined<VisitorIdentityService>('getJwtIdentity');

export const isVisitorServiceDefined = isModuleDefined<VisitorService>(
  'maintainVisitorState',
);

export const isMirroringDefined = isModuleDefined<MirroringService>('listen');
