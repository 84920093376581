/*_____________________________________________________________*/

import { v4 as uuid } from 'uuid';
import {
  Action,
  ActionType,
  AgentMessageAuthor,
  AttachmentType,
  AuthorType,
  CardAttachment,
  CardBundleAttachment,
  FileAttachement,
  ImageAttachement,
  Message,
  MessageAttachment,
  MessageAuthor,
  MessageConversationType,
  ProductOfferAttachment,
  ProductOfferAvailabilityStatus,
  ProductOfferBundleAttachment,
  QuickReplyMenuAttachement,
  EscalationInvitationSentAttachment,
  EscalationAcceptedAttachment,
  EscalationType,
  EscalationReason,
  EscalationEndedAttachment,
  ConversationType,
  HungUpAttachment,
  WaitingTimeEstimation,
  FeedbackSentAttachment,
  FeedbackReason,
  FeedbackRequestedAttachement,
} from '../../../chatbox/livechat-helpers/rfcConversation/Messages';

import {
  SystemMessage,
  SystemMessageAttachement,
  SystemMessageAttachementType,
  SystemTransferredToRoutingRule,
  SystemTransferredToParticipant,
} from '../../../chatbox/livechat-helpers/rfcConversation/SystemMessages';

import { UUID, Vuid } from '../../../chatbox/app-helpers/index';
import { LegacyOperator } from '../../../shared/types/globalTypes';
import XmppMessage from '../xmpp/domain/Message';
import { GenerativeAIFeedback } from '../../../shared/types/generativeAi';

type LegacyLinkAction = {
  type: 'link';
  title: string;
  url: string;
};

type LegacySelectAction = {
  type: 'select';
  title: string;
};

type LegacyAction = LegacyLinkAction | LegacySelectAction;

type LegacyImage = {
  url: string;
  description: string;
};

type LegacyCard = {
  image?: LegacyImage;
  title?: string;
  text?: string;
  actions: LegacyAction[];
};

type LegacyCards = {
  cards: LegacyCard[];
};

type LegacyAvailability = {
  status: string;
};

type LegacyProductOffer = {
  name: string;
  price: string;
  offerPrice?: string;
  internalProductId?: string;
  description?: string;
  image?: LegacyImage;
  availability?: LegacyAvailability;
  actions: LegacyAction[];
};

type LegacyProductOffers = {
  productOffers: LegacyProductOffer[];
};

type LegacyFile = {
  fileName: string;
  mimeType: string;
  url: URL;
};

export const getAuthorTypeFromLegacyRole = (
  role: string,
  clientId?: number,
) => {
  switch (role) {
    case 'operator':
      return AuthorType.OPERATOR;
    case 'manager':
    case 'ibbu-manager':
      return AuthorType.MANAGER;
    case 'admin':
      return AuthorType.ADMIN;
    case 'expert':
      return clientId ? AuthorType.CTY_EXPERT : AuthorType.IBBU_EXPERT;
    case 'bot':
      return AuthorType.BOT;
    case 'developer':
    case 'guest':
      return AuthorType.UNKNOWN;
    //__________________________________________________________
    default:
      return AuthorType.MANAGER;
  }
};

export const getAuthorFromLegacyOperator = (
  legacyOperator: LegacyOperator,
): AgentMessageAuthor => ({
  externalUrl: '',
  id: legacyOperator?.id,
  nickName: legacyOperator?.pseudo,
  pictureUrl: legacyOperator?.avatar,
  type: getAuthorTypeFromLegacyRole(
    legacyOperator?.role,
    legacyOperator?.client_id,
  ),
});

export const getAuthorFromBareJid = (bareJid: string): MessageAuthor => {
  const {
    Strophe,
    iAdvize: { operator, vuid },
  } = window;

  const isVisitor = vuid === Strophe.getResourceFromJid(bareJid);

  return isVisitor
    ? {
        type: AuthorType.VISITOR,
        id: bareJid,
      }
    : getAuthorFromLegacyOperator(operator as LegacyOperator);
};

export const toFileMessageAttachment = (
  fileName: string,
  mimeType: string,
  url: URL,
): FileAttachement => ({
  type: AttachmentType.FILE,
  data: {
    fileName,
    mimeType,
    url,
  },
});

export const toImageMessageAttachment = (
  fileName: string,
  mimeType: string,
  url: URL,
): ImageAttachement => ({
  type: AttachmentType.IMAGE,
  data: {
    fileName,
    mimeType,
    url,
  },
});

export const createRfcMessage = (
  fromBareJid: string,
  id: string,
  text: string = '',
  attachments: MessageAttachment[] = [],
): Message => {
  const author = getAuthorFromBareJid(fromBareJid);

  return {
    author,
    attachments,
    id,
    text,
    sentAs: author,
    type: MessageConversationType.MESSAGE,
    createdAt: new Date().toISOString(),
  };
};

export const createRfcVisitorMessage = (
  vuid: Vuid,
  text: string = '',
  attachments: MessageAttachment[] = [],
): Message => {
  const author: MessageAuthor = {
    type: AuthorType.VISITOR,
    id: `/${vuid}`,
  };

  return {
    author,
    attachments,
    id: uuid(),
    text,
    sentAs: author,
    type: MessageConversationType.MESSAGE,
    createdAt: new Date().toISOString(),
  };
};

export const createRfcSystemMessage = (
  attachments: SystemMessageAttachement[] = [],
): SystemMessage => ({
  attachments,
  id: uuid(),
  type: MessageConversationType.SYSTEM_MESSAGE,
  createdAt: new Date().toISOString(),
});

export const isImageMimeType = (mimeType: string) => /^image\//.test(mimeType);

export const getUploadMessageAttachment = (data: {
  name: string;
  type: string;
  url: URL;
}) => {
  if (isImageMimeType(data.type)) {
    return toImageMessageAttachment(data.name, data.type, data.url);
  }
  return toFileMessageAttachment(data.name, data.type, data.url);
};
export const getQuickReplyMenuMessageAttachment = (data: {
  message: string;
  choices: string[];
}): QuickReplyMenuAttachement => ({
  data,
  type: AttachmentType.QUICK_REPLY_MENU,
});

export const fromUploadToRfcMessage = (
  from: string,
  id: string,
  data: {
    name: string;
    type: string;
    url: URL;
  },
): Message => {
  return createRfcMessage(from, id, '', [getUploadMessageAttachment(data)]);
};

export const fromQCMToRfcMessage = (
  from: string,
  id: string,
  data: {
    message: string;
    choices: [];
  },
): Message =>
  createRfcMessage(from, id, '', [getQuickReplyMenuMessageAttachment(data)]);

export const fromOobToRfcMessage = (
  from: string,
  id: string,
  type: string,
  //________________________________________________________________
  data: any,
): Message => {
  switch (type) {
    case 'upload':
      return fromUploadToRfcMessage(from, id, data);
    case 'message-with-multiple-choices':
      return fromQCMToRfcMessage(from, id, data);
    default:
      return createRfcMessage(from, id, `UNSUPPORTED CONTENT (${type})`);
  }
};

const deduplicateAutomaticMessagesIds = (id: string) =>
  id === '555' || id === '777' ? `${id}-${+new Date()}` : id;

export const toRfcMessage = (
  message: Pick<XmppMessage, 'id' | 'from' | 'body'>,
  attachments: MessageAttachment[] = [],
): Message => {
  const author = getAuthorFromBareJid(message.from);

  return {
    author,
    attachments,
    id: deduplicateAutomaticMessagesIds(message.id || ''),
    type: MessageConversationType.MESSAGE,
    text: message.body,
    createdAt: new Date().toISOString(),
    sentAs: author,
  };
};

export const toRfcSystemMessage = (
  message: XmppMessage,
  attachments: SystemMessageAttachement[] = [],
): SystemMessage => ({
  attachments,
  id: message.id || '',
  type: MessageConversationType.SYSTEM_MESSAGE,
  createdAt: new Date().toISOString(),
});

export const engagementMessageToRfcMessage = (
  message: string,
  messageId: string,
): Message => {
  const author = {
    id: 'ENGAGEMENT_MESSAGE',
    type: AuthorType.OPERATOR,
    name: '',
    nickName: '',
  };

  return {
    author,
    attachments: [],
    id: messageId,
    type: MessageConversationType.MESSAGE,
    text: message,
    createdAt: new Date().toISOString(),
    sentAs: author,
  };
};

export const toRfcCobrowsingAskedMessage = (
  message: XmppMessage,
): SystemMessage => ({
  id: message.id || '',
  type: MessageConversationType.SYSTEM_MESSAGE,
  createdAt: new Date().toISOString(),
  attachments: [
    {
      type: SystemMessageAttachementType.COBROWSING_ASKED,
      data: {
        participant: getAuthorFromBareJid(message.from),
      },
    },
  ],
});

const toRfcAction = (action: LegacyAction): Action | undefined => {
  switch (action.type) {
    case 'link':
      return {
        title: action.title,
        url: action.url,
        type: ActionType.LINK,
      };
    case 'select':
      return {
        title: action.title,
        type: ActionType.SELECT,
      };
    default:
  }
};

const toRfcActions = (actions: LegacyAction[]): Action[] =>
  actions
    .map(toRfcAction)
    .filter((action: Action | undefined): action is Action => Boolean(action));

export const toRfcCardAttachment = ({
  actions,
  text,
  title,
  image,
}: LegacyCard): CardAttachment => ({
  type: AttachmentType.CARD,
  data: {
    title,
    text,
    image,
    actions: toRfcActions(actions),
  },
});

export const toRfcCardBundleAttachment = ({
  cards,
}: LegacyCards): CardBundleAttachment => ({
  type: AttachmentType.CARD_BUNDLE,
  data: {
    cards: cards.map(toRfcCardAttachment),
  },
});

export const toRfcVisitorNotificationSettingsSet = ({
  correlationId,
}: {
  correlationId: UUID;
}): SystemMessageAttachement => ({
  type: SystemMessageAttachementType.VISITOR_NOTIFICATION_SETTINGS_SET,
  data: {
    correlationId,
  },
});

export const toRfcVisitorNotificationSettingsRequested = ({
  correlationId,
  text,
}: {
  correlationId: UUID;
  text: string;
}): SystemMessageAttachement => ({
  type: SystemMessageAttachementType.VISITOR_NOTIFICATION_SETTINGS_REQUESTED,
  data: {
    correlationId,
    text,
  },
});

export const toRfcVisitorNotificationSettingsConfirmed = ({
  correlationId,
  text,
}: {
  correlationId: UUID;
  text: string;
}): SystemMessageAttachement => ({
  type: SystemMessageAttachementType.VISITOR_NOTIFICATION_SETTINGS_CONFIRMED,
  data: {
    correlationId,
    text,
  },
});

export const toRfcVisitorBlocked = (
  barejid: string,
): SystemMessageAttachement => ({
  type: SystemMessageAttachementType.VISITOR_BLOCKED,
  data: {
    participant: getAuthorFromBareJid(barejid),
  },
});

export const toRfcWaitingTimeEstimated = ({
  message,
  estimation,
}: {
  message: string;
  estimation: WaitingTimeEstimation;
}): SystemMessageAttachement => ({
  type: SystemMessageAttachementType.WAITING_TIME_ESTIMATED,
  data: {
    message,
    estimation,
  },
});

export const toRfcWaitingTimeExceeded = (
  message: string,
): SystemMessageAttachement => ({
  type: SystemMessageAttachementType.WAITING_TIME_EXCEEDED,
  data: {
    message,
  },
});

export const toRfcProductOfferAvailabilityStatus = (
  status: string,
): ProductOfferAvailabilityStatus => {
  switch (status) {
    case 'available':
      return ProductOfferAvailabilityStatus.AVAILABLE;
    case 'unavailable':
    default:
      return ProductOfferAvailabilityStatus.UNAVAILABLE;
  }
};

export const toRfcProductOfferAvailability = (
  availability?: LegacyAvailability,
) => {
  if (availability) {
    return {
      status: toRfcProductOfferAvailabilityStatus(availability.status),
    };
  }
};

export const toRfcProductOfferAttachment = ({
  image,
  name,
  price,
  offerPrice,
  internalProductId,
  availability,
  description,
  actions,
}: LegacyProductOffer): ProductOfferAttachment => ({
  type: AttachmentType.PRODUCT_OFFER,
  data: {
    name,
    price,
    offerPrice,
    internalProductId,
    description,
    image,
    availability: toRfcProductOfferAvailability(availability),
    actions: toRfcActions(actions),
  },
});

export const toRfcProductOfferBundleAttachment = ({
  productOffers,
}: LegacyProductOffers): ProductOfferBundleAttachment => ({
  type: AttachmentType.PRODUCT_OFFER_BUNDLE,
  data: {
    productOffers: productOffers.map(toRfcProductOfferAttachment),
  },
});

export const toRfcFileAttachment = ({
  fileName,
  mimeType,
  url,
}: LegacyFile): FileAttachement => ({
  type: AttachmentType.FILE,
  data: {
    fileName,
    mimeType,
    url,
  },
});

export const toRfcSystemTransferredToRoutingRule = ({
  fromParticipantJid,
  routingRuleId,
  routingRuleLabel,
  reason,
}: {
  fromParticipantJid: string;
  routingRuleLabel: string;
  routingRuleId: string;
  reason: string;
}): SystemTransferredToRoutingRule => ({
  type: SystemMessageAttachementType.TRANSFERRED_TO_ROUTING_RULE,
  data: {
    fromParticipant: { type: AuthorType.UNKNOWN, id: fromParticipantJid },
    toRule: {
      routingRuleId,
      routingRuleLabel,
    },
    reason,
  },
});

export const toRfcSystemTransferredToParticipant = ({
  fromParticipantJid,
  toParticipantJid,
  reason,
}: {
  fromParticipantJid: string;
  toParticipantJid: string;
  reason: string;
}): SystemTransferredToParticipant => ({
  type: SystemMessageAttachementType.TRANSFERRED_TO_PARTICIPANT,
  data: {
    fromParticipant: { type: AuthorType.UNKNOWN, id: fromParticipantJid },
    toParticipant: { type: AuthorType.UNKNOWN, id: toParticipantJid },
    reason,
  },
});

export const toRfcEscalationInvitationSentAttachment = ({
  type,
  correlationId,
}: {
  correlationId: UUID;
  type: EscalationType;
}): EscalationInvitationSentAttachment => ({
  type: AttachmentType.ESCALATION_INVITATION_SENT,
  data: {
    type,
    correlationId,
  },
});

export const toRfcEscalationAcceptedAttachment = ({
  type,
  correlationId,
}: {
  correlationId: UUID;
  type: EscalationType;
}): EscalationAcceptedAttachment => ({
  type: AttachmentType.ESCALATION_ACCEPTED,
  data: {
    type,
    correlationId,
  },
});

export const toRfcEscalationEndedAttachment = ({
  type,
  correlationId,
  reason,
}: {
  correlationId: UUID;
  type: EscalationType;
  reason: EscalationReason;
}): EscalationEndedAttachment => ({
  type: AttachmentType.ESCALATION_ENDED,
  data: {
    type,
    correlationId,
    reason,
  },
});

export const toRfcFeedbackAttachment = ({
  messageId,
  feedback,
}: {
  messageId: UUID;
  feedback: GenerativeAIFeedback;
}): FeedbackSentAttachment => ({
  type: AttachmentType.FEEDBACK_SENT,
  data: {
    feedback,
    messageId,
  },
});

export const toRfcFeedbackRequestedAttachment = ({
  reason,
  text,
}: {
  reason: FeedbackReason;
  text: string;
}): FeedbackRequestedAttachement => ({
  type: AttachmentType.FEEDBACK_REQUESTED,
  text,
  data: {
    reason,
  },
});

export const toRfcHungupAttachment = ({
  type,
  correlationId,
}: {
  correlationId: UUID;
  type: ConversationType;
}): HungUpAttachment => ({
  type: AttachmentType.HUNG_UP,
  data: { type, correlationId },
});
